@import './scss/variables.scss';
@import './scss/mixins.scss';
@import './scss/global.scss';

.container {
  display: flex;
  @include breakpoint(small) {
    flex-direction: column;
  }
}

.app-header,
#content {
  width: 640px;
  @include breakpoint(default) {
    display: inline-block;
    vertical-align: top;
  }
}

.app-header {
  margin: 8rem 0 0 6rem;
  text-align: right;
  flex: 1;
  @include clear();

  @include breakpoint(small) {
    margin: 0 auto;
    text-align: left;
    padding: 1rem;
  }
}

.app-heading {
  margin: 0;
}

#content {
  margin: 2rem;
  flex: 2;

  @include breakpoint(small) {
    padding: 1rem;
    margin: -1rem auto 0 auto;
  }
}

.svisible {
  opacity: 0;
}

.visible {
  transition: opacity 1s, transform 0.2s;
  opacity: 1;
  transform: perspective(0) translate(0, 0);
}
