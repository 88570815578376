@import '../../scss/variables';
@import '../../scss/mixins.scss';

.site-nav {
  font-size: 1.2rem;
}

.site-links {
  display: flex;
  flex-direction: column;
}

.nav-link,
.external-link {
  font-size: larger;
  display: inline-block;
  border-bottom: solid 2px transparent;
  padding: 0.3rem;
  transition: color 0.5s;
}

.nav-link:hover {
  color: $darkerGreen;
  text-decoration: none;
}

.nav-link.active {
  color: $darkerGreen;
  border-bottom-color: $green;
}

.site-sublinks {
  display: flex;
  flex-direction: column;

  .nav-link {
    font-size: smaller;
    padding: 0;
  }
}

.external-links {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .nav-text {
    display: none;
  }
}

.mobile-sublinks {
  display: none;
}

@include breakpoint(small) {
  .site-sublinks {
    display: none;
  }

  .mobile-sublinks {
    display: flex;
    justify-content: center;

    .nav-link {
      font-size: smaller;
    }
  }

  .nav-item::after {
    content: '\00B7';
    color: $green;
  }

  .nav-item:last-child::after {
    content: '';
  }

  .site-links {
    flex-direction: row;
    justify-content: center;
  }

  .external-links {
    margin-top: 0;
    justify-content: center;
  }
}
